.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-shrink: 1;
    width: auto;
    justify-content: space-between;
    margin: auto;
   
    
    
  }
  
  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    column-fill: auto;
    
  }

  .grid {

    display: grid;
    grid-template-columns: repeat(3,auto);
    grid-template-rows: auto;
    grid-auto-columns: auto;
    grid-auto-rows: auto;
    justify-content: space-evenly;
    grid-gap: 10px;
    

  }

  .photo {
    width: 300px;
    height: auto;
    object-fit: scale-down;
    }
    
    .logo {
     max-width: 25%;
      height: auto;


    }
    .section {
        display: flexbox;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        overflow: scroll;

    }

    .heading {
        text-wrap: pretty;
        text-align:left;
        padding: 20px;
      }


      .ul-list {
        display: flex;
        flex-wrap: wrap;
      }

      .li-list {
        height: 40vh;
        flex-grow: 1;
      }

      .img-list {
        max-height: 100%;
        min-width: 100%;
        object-fit: cover;
        vertical-align: bottom;
      }

      .gal {
        width:100% ;
      }
