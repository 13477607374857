.contact-page-input {
  height: 3em;
  width: 100%;
  padding: 0.5em 1em;
  margin-bottom: 1em;

  border: 0;
  background-color: #EDE9E9;
  color: #000;
}

.contact-page-input-tall {
  height: 15em;
}