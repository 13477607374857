.cd-container {
width: 100%;
max-width: 1080px;
margin: 0 auto;
// background: #2B343A;
padding: 0 10%;
border-radius: 2px;
}
.cd-container::after {
content: '';
display: table;
clear: both;
}

/* -------------------------------- 

Main components 

-------------------------------- */


#cd-timeline {
position: relative;
padding: 2em 0;
margin-top: 2em;
margin-bottom: 2em;
}
#cd-timeline::before {
content: '';
position: absolute;
top: 0;
left: 25px;
height: 100%;
width: 2px;
background: #DEDCDC; // timeline line color
}
@media only screen and (min-width: 1170px) {
#cd-timeline {
  margin-top: 3em;
  margin-bottom: 3em;
}
#cd-timeline::before {
  left: 50%;
  margin-left: -1px;
}
}

.cd-timeline-block {
position: relative;
margin: 2em 0;
}
.cd-timeline-block:after {
content: "";
display: table;
clear: both;
}
.cd-timeline-block:first-child {
margin-top: 0;
}
.cd-timeline-block:last-child {
margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
.cd-timeline-block {
  margin: 4em 0;
}
.cd-timeline-block:first-child {
  margin-top: 0;
}
.cd-timeline-block:last-child {
  margin-bottom: 0;
}
}

.cd-timeline-img {
position: absolute;
top: 8px;
left: 16px;
width: 20px;
height: 20px;
border-radius: 50%;
}
.cd-timeline-img {
background: #DB3934; // dot color
}
@media only screen and (min-width: 1170px) {
.cd-timeline-img {
  width: 20px;
  height: 20px;
  left: 50%;
  margin-left: -10px;
  margin-top: 10px;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
}

.cd-timeline-content {
position: relative;
margin-left: 60px;
margin-right: 30px;
background: #252323; // box bg
border-radius: 7px;
padding: 1em;
}
.red {
background: #DB3934; // box bg
}

.cd-timeline-content:after {
content: "";
display: table;
clear: both;
}
// .cd-timeline-content h2 {
// color: rgba(255,255,255,.9);
// margin-top:0;
// margin-bottom: 5px;
// }
.cd-timeline-content p, .cd-timeline-content .cd-date {
color: rgb(255, 255, 255); // box font color
font-size: 13px;
font-size: 0.8125rem;
}

.cd-timeline-content .cd-date {
color: rgb(0, 0, 0); // date font color
font-weight: 900;
display: inline-block;
}
@media only screen and (max-width: 1170px) {
.cd-timeline-content .cd-date {
  color: rgb(228, 228, 228); // date font color
}
}
.cd-timeline-content p {
margin: 0.2em 0;
line-height: 1.4;
}

.cd-timeline-content::before {
content: '';
position: absolute;
top: 16px;
right: 100%;
height: 0;
width: 0;
border: 7px solid transparent;
border-right: 7px solid #252323; // arrow color when small
}
.red::before {
  border-right: 7px solid #DB3934; // arrow color when small
}

@media only screen and (min-width: 768px) {
// .cd-timeline-content h2 {
//   font-size: 20px;
//   font-size: 1.25rem;
// }
.cd-timeline-content p {
  font-size: 16px;
  font-size: 1rem;
}
.cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
  font-size: 14px;
  font-size: 0.875rem;
}
}
@media only screen and (min-width: 1170px) {
.cd-timeline-content {
  color: white;
  margin-left: 0;
  padding: 1.6em;
  width: 36%;
  margin: 0 5%
}
.cd-timeline-content::before {
  top: 24px;
  left: 100%;
  border-color: transparent;
  border-left-color: #252323; // left box arrow color
}
.red::before {
  border-left-color: #DB3934; // left box arrow color
}

.cd-timeline-content .cd-date {
  position: absolute;
  width: 100%;
  // left: 122%;
  left: 140%;
  top: 6px;
  font-size: 16px;
  font-size: 1rem;
}
.cd-timeline-block:nth-child(even) .cd-timeline-content {
  float: right;
}
.cd-timeline-block:nth-child(even) .cd-timeline-content::before {
  top: 24px;
  left: auto;
  right: 100%;
  border-color: transparent;
  border-right-color: #252323; // right box arrow color
}
.cd-timeline-block:nth-child(even) .red::before {
border-right-color: #DB3934; // right box arrow color
}
.cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
  float: right;
}
.cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
  left: auto;
  // right: 122%;
  right: 140%;
  text-align: right;
}
}

.cd-timeline-content a {
color:rgb(255, 255, 255)
}