.markets-inside-wrapper {
  padding: 2em 1em;
}
.markets-inside-wrapper h4 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0.5em;
}
.markets-li {
  margin-bottom: 1em;
}

.markets-red-circle {
  width: 60px;
  height: 60px;
  text-align: center;
  border-radius: 50%;
  background-color: #DB3934;
}
.markets-red-circle p {
  line-height: 60px;
  font-size: 30px;
  font-weight: 900;
  color: white;
}

.responsive-img {
  width: 100%;
  height: auto;
}

.resource-links a {
  font-size: 20px;
}