.normal-container {
  padding-top: 2em;
  padding-bottom: 2em;
}
.content-section {
  padding-bottom: 3em;
}

.site-toast-style { 
  position: fixed;
  z-index: 100000000;
  bottom: 5%;
  left: 50%;
  -ms-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  padding-left: 3em; 
  padding-right: 3em; 
  font-size: 16px;
  color: black;
  background-color: #cecece;
  box-shadow: 0 0 20px #25232388;
}

.separator {
  width: 48px;
  height: 3px;
  background-color: #DB3934;
  margin: 20px 0;
}


.nav-link-style {
  color: #FFFB;
  margin-left: 20px;
  padding: 7px 0;
}
.nav-link-style:hover { 
  text-decoration: none;
  color: #FFF;
}

#footer {
  color: #FFFFFF;
  background-color: #252323;
}
#footer-red-bar {
  background-color: #DB3934;
  width: 100%;
  height: 13px;
}
#footer-content {
  padding: 3em 0;
}
.footer-col {
  padding-left: 50px;
}
.footer-heading {
  font-weight: 700;
  font-size: 20px;
}
.footer-text {
  font-weight: 400;
  font-size: 16px;
}
.footer-text b {
  font-weight: 700;
  font-size: 16px;
}